<template>
  <MainContent>
    <div class="container" v-if="selectActiveCompany" :key="selectActiveCompany._id">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-xl-8">
          <PromoCreationView v-if="showOrgPromos" />
          <div v-else>
            <CreatePromoHeader />
            <CreatePromoWrapper />
          </div>
        </div>
      </div>
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import CreatePromoHeader from '@/components/Modules/Promo/CreatePromo/CreatePromoHeader'
import CreatePromoWrapper from '@/components/Modules/Promo/CreatePromo/CreatePromoWrapper'
import PromoCreationView from '@/components/Modules/PromoV2/PromoCreation/PromoCreationView'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')
const FeatureFlagModule = createNamespacedHelpers('featureFlag')
const OrganizationModule = createNamespacedHelpers('organization')

export default {
  name: 'CreatePromoPage',
  components: {
    MainContent,
    CreatePromoHeader,
    CreatePromoWrapper,
    PromoCreationView,
  },
  computed: {
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    ...FeatureFlagModule.mapGetters(['isEnabled']),
    ...OrganizationModule.mapGetters(['selectOrganization']),
    showOrgPromos() {
      return (
        (this.selectActiveCompany?._id === 'ALL_COMPANIES' && !!this.selectOrganization) ||
        (this.selectActiveCompany?._id !== 'ALL_COMPANIES' &&
          this.isEnabled('HIDE_LEGACY_CAMPAIGNS'))
      )
    },
  },
}
</script>

<style scoped lang="scss"></style>
