<template>
  <div>
    <PromoCreationHeader />
    <PromoCreationForm v-if="initialized" />
    <div v-else>
      <div v-for="x in 5" :key="x">
        <div class="mb-5">
          <b-skeleton width="30%" height="50px" />
          <b-skeleton width="100%" height="50px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PromoCreationForm from './Components/PromoCreationForm'
import PromoCreationHeader from './Components/PromoCreationHeader'
import { createNamespacedHelpers } from 'vuex'

const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'PromoCreationView',
  components: {
    PromoCreationHeader,
    PromoCreationForm,
  },
  data: () => ({
    initialized: false,
  }),
  async mounted() {
    await this.getUserLocationsByActiveCompany()
    this.initialized = true
  },
  methods: {
    ...LocationModule.mapActions(['getUserLocationsByActiveCompany']),
  },
}
</script>
