<template>
  <div>
    <CreatePromoForm ref="form" />
  </div>
</template>

<script>
import CreatePromoForm from './CreatePromoForm'
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('promo')
export default {
  name: 'CreatePromoWrapper',
  components: {
    CreatePromoForm,
  },
  computed: {
    ...mapState(['promo']),
  },
  data: () => ({}),
  methods: {},
}
</script>
